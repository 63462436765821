// importScripts('./ngsw-worker.js');

self.addEventListener("install", function (event) {
	console.log("Install service worker");
});

self.addEventListener("message", (ev) => {
	if (ev.data.notifs) {
		ev.data.notifs.forEach((n) => {
			// const time = (new Date(n.deliveryDate)).getTime() - (new Date()).getTime()
			const time = n.deliveryDate - new Date().getTime();
			if (time / 1000 >= -1 && time <= 86_400_000) {
				setTimeout(() => {
					self.postMessage({ displayedNotifId: n.uid });
				}, time);
			}
		});
	}
});
